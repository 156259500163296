import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;

	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// Full screen
	public fullScreen: boolean = false;

	constructor(private router: Router) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if (evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if (window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}
	}

	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	COMPANY_MENUITEMS: Menu[] = [
		{
			title: 'Dashboard', icon: 'home', type: 'sub', badgeType: 'success', children: [
				{ path: '/dashboard', title: 'Dashboard', icon: 'home', type: 'link' },
				//{ path: '/purchase/store-available-stocks', title: 'Product Stocks', icon: 'check-square', type: 'link' },
				//{ path: '/reports/store-recipe-report', title: 'Recipe Stock', type: 'link' },
				{ path: '/purchase/company-available-stocks', title: 'Product Stocks', icon: 'check-square', type: 'link' },
				{ path: '/reports/company-recipe-report', title: 'Recipe Stocks', type: 'link' },
			]
		},
		{
			title: 'Configurations', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/masters/brand', title: 'Brand', type: 'link' },
				{ path: '/masters/category', title: 'Category', type: 'link' },
				{ path: '/masters/sub-category', title: 'Sub Category', type: 'link' },
				{ path: '/masters/supplier', title: 'Supplier', type: 'link' },
				{ path: '/masters/customer', title: 'Customer', type: 'link' },

				{ path: '/masters/product', title: 'Product', type: 'link' },
				{ path: '/masters/measuring-units', title: 'Measuring Units', type: 'link' },
				{ path: '/masters/product-recipes', title: 'Product Recipes', type: 'link' },
				{ path: '/masters/sellable-product-mapping', title: 'POS Mapping', type: 'link' },
				{ path: '/settings', title: 'Settings', type: 'link' },
				{ path: '/transfer/move-recipes', title: 'Map Recipes and Products', type: 'link' }


			]
		},
	
		{
			title: 'Purchase', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/purchase/purchase-request/purchase-request-list-for-approve', title: 'Purchase Requests Approval', type: 'link' },
				{ path: '/purchase/purchase-request/consolidated-purchase-request-items-for-quotation-request', title: 'Quotation Request', type: 'link' },
				{ path: '/purchase/purchase-order', title: 'Purchase Order', type: 'link' },
				{ path: '/purchase/purchase-invoice', title: 'Purchase Invoice', type: 'link' },
				{ path: '/purchase/purchase-return', title: 'Purchase Return', type: 'link' },
				{ path: '/purchase/unpaid-purchase-stocks', title: 'Pending Bills', type: 'link' },
				{ path: '/purchase/supplier-payment', title: 'Supplier Payment', type: 'link' },
				{ path: '/sales/sales-order', title: 'Sales Order', type: 'link' },
			
			
			]
		},
		
		{
			title: 'Inventory', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/inventory/inventory-transactions', title: 'Transactions', type: 'link' },
				{ path: '/production', title: 'Production', icon: 'check-square', type: 'link' },
				{ path: '/purchase/company-available-stocks', title: 'Product Stocks', icon: 'check-square', type: 'link' },
				{ path: '/reports/company-recipe-report', title: 'Recipe Stock', type: 'link' },
				{ path: '/inventory/unsettled-recipe-transactions', title: 'Recipe Settlement' , type: 'link' },
				{ path: '/inventory/unsettled-raw-product-transactions', title: 'Product Settlement', type: 'link' },
				{ path: '/inventory/stock-excel-upload', title: 'Stock Excel Upload', type: 'link' },
				{ path: '/inventory/stock-adjustment', title: 'Stock Adjustment', type: 'link' }
			]
		},
		
		{
			title: 'Stock Transfer', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/inventory/stock-transfer-request', title: 'Transfer Request', type: 'link' },
				{ path: '/inventory/stock-transfer', title: 'Stock Transfer', type: 'link' },
				{ path: '/inventory/stock-transfer/pending-to-recieve', title: 'Recieve Transfer', type: 'link' },


			]
		},

		{
			title: 'Reports', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/reports/stock-report', title: 'Stock Report', type: 'link' },
				{ path: '/reports/purchase-report', title: 'Purchase Report', type: 'link' },
				{ path: '/reports/recipe-stock-report', title: 'Recipe Stock Report', type: 'link' },
				{ path: '/inventory/inventory-transactions-report', title: 'Transactions Report', type: 'link' },
				{ path: '/reports/purchase-report-by-product', title: 'Purchase By Product', type: 'link' },
				{ path: '/reports/raw-product-transactions-report', title: 'Product Transactions Report', type: 'link' },
				{ path: '/reports/recipe-transactions-report', title: 'Recipe Transactions Report', type: 'link' },
				{ path: '/reports/stock-transfer-report', title: 'Stock Transfer Report', type: 'link' },
				{ path: '/reports/stock-transfer-report-by-item', title: 'Stock Transfer Report By Item', type: 'link' },
				{ path: '/reports/purchase-request-report-by-date', title: 'Purchase Request Report By Date', type: 'link' },
				{ path: '/reports/purchase-return-report', title: 'Purchase Return Report', type: 'link' },
				{ path: '/reports/quotation-request-report-by-date', title: 'Quotation Request Report By Date', type: 'link' },
				{ path: '/reports/purchase-order-report-by-date', title: 'Purchase Order Report By Date', type: 'link' },
				{ path: '/reports/profit-report', title: 'Profit Report', type: 'link' },
				{ path: '/reports/supplier-detail-report', title: 'Supplier Detail Report', type: 'link' },
				{ path: '/reports/supplier-payment-report', title: 'Supplier Payment Report', type: 'link' },
				{ path: '/reports/product-consumption-report', title: 'Product Consumption Report', type:'link'},
				{ path: '/reports/recipe-consumption-report',title:'Recipe Consumtion Report', type: 'link'},
				{ path: '/reports/sales-order-report',title:'Sales Order Report', type: 'link'},
				{ path: '/reports/stock-detail-report',title:'Stock Detail Report', type: 'link'},
				{ path: '/reports/stock-detail-report-by-date',title:'Stock Detail Report By Date', type: 'link'},
				{ path: '/reports/sales-report-by-product',title:'Sales Report by product', type: 'link'}

			]
		},

		


	];

	STORE_MENUITEMS: Menu[] = [
		{
			title: 'Dashboard', icon: 'home', type: 'sub', badgeType: 'success', children: [
				{ path: '/dashboard', title: 'Dashboard', icon: 'home', type: 'link' },
				{ path: '/purchase/store-available-stocks', title: 'Product Stocks', icon: 'check-square', type: 'link' },
				{ path: '/reports/store-recipe-report', title: 'Recipe Stock', type: 'link' },
			]
		},
		{
			title: 'Purchase', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/purchase/purchase-order', title: 'Purchase Order', type: 'link' },
				{ path: '/purchase/purchase-invoice', title: 'Purchase Invoice', type: 'link' },
				{ path: '/purchase/good-reciept-note', title: 'Good Receipt Note', type: 'link' },
				{ path: '/purchase/unpaid-purchase-stocks', title: 'Pending Bills', type: 'link' },
				{ path: '/sales/sales-order', title: 'Sales Order', type: 'link' },
			]
		},
		
		{
			title: 'Inventory', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/inventory/inventory-transactions', title: 'Transactions', type: 'link' },
				{ path: '/production', title: 'Production', icon: 'check-square', type: 'link' },
				{ path: '/purchase/store-available-stock', title: 'Product Stocks', icon: 'check-square', type: 'link' },
				{ path: '/reports/store-recipe-reports', title: 'Recipe Stock', type: 'link' },
				{ path: '/inventory/unsettled-recipe-transactions', title: 'Recipe Settlement' , type: 'link' },
				{ path: '/inventory/unsettled-raw-product-transactions', title: 'Product Settlement', type: 'link' },
				{ path: '/inventory/stock-excel-upload', title: 'Stock Excel Upload', type: 'link' },
				{ path: '/inventory/stock-adjustment', title: 'Stock Adjustment', type: 'link' }
			]
		},
		{
			title: 'Stock Transfer', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/inventory/stock-transfer-request', title: 'Transfer Request', type: 'link' },
				{ path: '/inventory/stock-transfer', title: 'Stock Transfer', type: 'link' },
				{ path: '/inventory/stock-transfer/pending-to-recieve', title: 'Recieve Transfer', type: 'link' },


			]
		},
		{
			title: 'Reports', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/reports/stock-report', title: 'Stock Report', type: 'link' },
				{ path: '/reports/purchase-report', title: 'Purchase Report', type: 'link' },
				{ path: '/reports/recipe-stock-report', title: 'Recipe Stock Report', type: 'link' },
				{ path: '/inventory/inventory-transactions-report', title: 'Transactions Report', type: 'link' },
				{ path: '/reports/purchase-report-by-product', title: 'Purchase By Product', type: 'link' },
				{ path: '/reports/raw-product-transactions-report', title: 'Product Transactions Report', type: 'link' },
				{ path: '/reports/recipe-transactions-report', title: 'Recipe Transactions Report', type: 'link' },
				{ path: '/reports/stock-transfer-report', title: 'Stock Transfer Report', type: 'link' },
				{ path: '/reports/stock-transfer-report-by-item', title: 'Stock Transfer Report By Item', type: 'link' },
				{ path: '/reports/good-receipt-note-report-by-date', title: 'Good Receipt Note Report', type: 'link' },
				{ path: '/reports/profit-report', title: 'Profit Report', type: 'link' },
				{ path: '/reports/product-consumption-report', title: 'Product Consumption Report', type:'link'},
				{ path: '/reports/recipe-consumption-report',title:'Recipe Consumtion Report', type: 'link'},
				{ path: '/reports/stock-detail-report',title:'Stock Detail Report', type: 'link'},
				{ path: '/reports/stock-detail-report-by-date',title:'Stock Detail Report By Date', type: 'link'},
				{ path: '/reports/sales-report-by-product',title:'Sales Report by product', type: 'link'}
			
			]
		},
		{
			title: 'Configurations', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/masters/product', title: 'Product', type: 'link' },
				// { path: '/masters/measuring-units', title: 'Measuring Units', type: 'link' },
				{ path: '/masters/product-recipes', title: 'Product Recipes', type: 'link' },
				{ path: '/masters/sellable-product-mapping', title: 'POS Mapping', type: 'link' },
			]
		},
		// {
		// 	title: 'Accounts', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
		// 		{ path: '/transactions/transactions', title: 'Transactions', type: 'link' },
		// 	]
		// }
	];
	SINGLE_STORE_MENUITEMS: Menu[] = [
		{
			title: 'Dashboard', icon: 'home', type: 'sub', badgeType: 'success', children: [
				{ path: '/dashboard', title: 'Dashboard', icon: 'home', type: 'link' },
				{ path: '/purchase/store-available-stocks', title: 'Product Stocks', icon: 'check-square', type: 'link' },
				{ path: '/reports/store-recipe-report', title: 'Recipe Stock', type: 'link' },
			]
		},
		{
			title: 'Purchase', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/purchase/purchase-order', title: 'Purchase Order', type: 'link' },
				{ path: '/purchase/purchase-invoice', title: 'Purchase Invoice', type: 'link' },
				{ path: '/purchase/good-reciept-note', title: 'Good Receipt Note', type: 'link' },
				{ path: '/purchase/unpaid-purchase-stocks', title: 'Pending Bills', type: 'link' },
			]
		},
		{
			title: 'Inventory', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/inventory/inventory-transactions', title: 'Transactions', type: 'link' },
				{ path: '/production', title: 'Production', icon: 'check-square', type: 'link' },
				{ path: '/purchase/store-available-stock', title: 'Product Stocks', icon: 'check-square', type: 'link' },
				{ path: '/reports/store-recipe-reports', title: 'Recipe Stock', type: 'link' },
				{ path: '/inventory/unsettled-recipe-transactions', title: 'Recipe Settlement' , type: 'link' },
				{ path: '/inventory/unsettled-raw-product-transactions', title: 'Product Settlement', type: 'link' },
				{ path: '/inventory/stock-excel-upload', title: 'Stock Excel Upload', type: 'link' },

			]
		},
		{
			title: 'Stock Transfer', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/inventory/stock-transfer-request', title: 'Transfer Request', type: 'link' },
				{ path: '/inventory/stock-transfer', title: 'Stock Transfer', type: 'link' },
				{ path: '/inventory/stock-transfer/pending-to-recieve', title: 'Recieve Transfer', type: 'link' },


			]
		},
		{
			title: 'Reports', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/reports/stock-report', title: 'Stock Report', type: 'link' },
				{ path: '/reports/purchase-report', title: 'Purchase Report', type: 'link' },
				{ path: '/reports/recipe-stock-report', title: 'Recipe Stock Report', type: 'link' },
				{ path: '/inventory/inventory-transactions-report', title: 'Transactions Report', type: 'link' },
				{ path: '/reports/purchase-report-by-product', title: 'Purchase By Product', type: 'link' },
				{ path: '/reports/raw-product-transactions-report', title: 'Product Transactions Report', type: 'link' },
				{ path: '/reports/recipe-transactions-report', title: 'Recipe Transactions Report', type: 'link' },
				{ path: '/reports/stock-transfer-report', title: 'Stock Transfer Report', type: 'link' },
				{ path: '/reports/stock-transfer-report-by-item', title: 'Stock Transfer Report By Item', type: 'link' },
				{ path: '/reports/good-receipt-note-report-by-date', title: 'Good Receipt Note Report', type: 'link' },
			
			]
		},
		{
			title: 'Configurations', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/masters/brand', title: 'Brand', type: 'link' },
				{ path: '/masters/category', title: 'Category', type: 'link' },
				{ path: '/masters/sub-category', title: 'Sub Category', type: 'link' },
				{ path: '/masters/supplier', title: 'Supplier', type: 'link' },
				{ path: '/masters/product', title: 'Product', type: 'link' },
				{ path: '/masters/measuring-units', title: 'Measuring Units', type: 'link' },
				{ path: '/masters/product-recipes', title: 'Product Recipes', type: 'link' },
				{ path: '/masters/sellable-product-mapping', title: 'POS Mapping', type: 'link' },
				{ path: '/settings', title: 'Settings', type: 'link' },
			
			]
		},
		// {
		// 	title: 'Accounts', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
		// 		{ path: '/transactions/transactions', title: 'Transactions', type: 'link' },
		// 	]
		// }
	];
	WARE_HOUSE_MENUITEMS: Menu[] = [
		{
			title: 'Dashboard', icon: 'home', type: 'sub', badgeType: 'success', children: [
				{ path: '/dashboard', title: 'Dashboard', icon: 'home', type: 'link' },
				{ path: '/purchase/store-available-stocks', title: 'Product Stocks', icon: 'check-square', type: 'link' },
				{ path: '/reports/store-recipe-report', title: 'Recipe Stock', type: 'link' },
			]
		},
		{
			title: 'Purchase', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/purchase/purchase-request/consolidated-stock-request-items-for-purchase-request', title: 'Purchase Request', type: 'link' },
				{ path: '/purchase/purchase-order', title: 'Purchase Order', type: 'link' },
				{ path: '/purchase/purchase-invoice', title: 'Purchase Invoice', type: 'link' },
				{ path: '/purchase/good-reciept-note', title: 'Good Receipt Note', type: 'link' },
				{ path: '/purchase/unpaid-purchase-stocks', title: 'Pending Bills', type: 'link' },
			]
		},
		{
			title: 'Inventory', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/inventory/inventory-transactions', title: 'Transactions', type: 'link' },
				{ path: '/production', title: 'Production', icon: 'check-square', type: 'link' },
				{ path: '/purchase/store-available-stock', title: 'Product Stocks', icon: 'check-square', type: 'link' },
				{ path: '/reports/store-recipe-reports', title: 'Recipe Stock', type: 'link' },
				{ path: '/inventory/unsettled-recipe-transactions', title: 'Recipe Settlement', type: 'link' },
				{ path: '/inventory/unsettled-raw-product-transactions', title: 'Product Settlement', type: 'link' },
				{ path: '/inventory/stock-excel-upload', title: 'Stock Excel Upload', type: 'link' },
			]
		},
		{
			title: 'Stock Transfer', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/inventory/stock-transfer-request', title: 'Transfer Request', type: 'link' },
				{ path: '/inventory/stock-transfer', title: 'Stock Transfer', type: 'link' },
				{ path: '/inventory/stock-transfer/pending-to-recieve', title: 'Recieve Transfer', type: 'link' },


			]
		},
		{
			title: 'Reports', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/reports/stock-report', title: 'Stock Report', type: 'link' },
				{ path: '/reports/purchase-report', title: 'Purchase Report', type: 'link' },
				{ path: '/reports/recipe-stock-report', title: 'Recipe Stock Report', type: 'link' },
				{ path: '/inventory/inventory-transactions-report', title: 'Transactions Report', type: 'link' },
				{ path: '/reports/purchase-report-by-product', title: 'Purchase By Product', type: 'link' },
				{ path: '/reports/raw-product-transactions-report', title: 'Product Transactions Report', type: 'link' },
				{ path: '/reports/recipe-transactions-report', title: 'Recipe Transactions Report', type: 'link' },
				{ path: '/reports/stock-transfer-report', title: 'Stock Transfer Report', type: 'link' },
				{ path: '/reports/stock-transfer-report-by-item', title: 'Stock Transfer Report By Item', type: 'link' },
			]
		},
		{
			title: 'Configurations', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/masters/product', title: 'Product', type: 'link' },
				// { path: '/masters/measuring-units', title: 'Measuring Units', type: 'link' },
				{ path: '/masters/product-recipes', title: 'Product Recipes', type: 'link' },
				{ path: '/masters/sellable-product-mapping', title: 'POS Mapping', type: 'link' },
			]
		},
		// {
		// 	title: 'Accounts', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
		// 		{ path: '/transactions/transactions', title: 'Transactions', type: 'link' },
		// 	]
		// }
	];

	MEGAMENUITEMS: Menu[] = [
		{
			title: 'Error Pages', type: 'sub', active: true, children: [
				{ path: 'javascript:void(0);', title: 'Error Page 400', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 401', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 403', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 404', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 500', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 503', type: 'extLink' },
			]
		},
		{
			title: 'Authentication', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'Login Simple', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Login BG Image', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Login BG Video', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Simple Register', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Register BG Image', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Register BG Video', type: 'extLink' }
			]
		},
		{
			title: 'Usefull Pages', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'Search Pages', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Unlock User', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Forgot Password', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Reset Password', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Maintenance', type: 'extLink' }
			]
		},
		{
			title: 'Email templates', type: 'sub', active: false, children: [
				{ path: 'http://admin.pixelstrap.com/eDineInventoryCMS/theme/basic-template.html', title: 'Basic Email', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/eDineInventoryCMS/theme/email-header.html', title: 'Basic With Header', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/eDineInventoryCMS/theme/template-email.html', title: 'Ecomerce Template', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/eDineInventoryCMS/theme/template-email-2.html', title: 'Email Template 2', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/eDineInventoryCMS/theme/ecommerce-templates.html', title: 'Ecommerce Email', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/eDineInventoryCMS/theme/email-order-success.html', title: 'Order Success', type: 'extTabLink' }
			]
		},
		{
			title: 'Coming Soon', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'Coming Simple', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Coming BG Image', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Coming BG Video', type: 'extLink' }
			]
		},
	];

	LEVELMENUITEMS: Menu[] = [
		{
			path: 'javascript:void(0);', title: 'File Manager', icon: 'git-pull-request', type: 'extLink'
		},
		{
			title: 'Users', icon: 'users', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'All Users', icon: 'users', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'User Profile', icon: 'users', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Edit Profile', icon: 'users', type: 'extLink' },
			]
		},
		{ path: 'javascript:void(0);', title: 'Bookmarks', icon: 'heart', type: 'extLink' },
		{ path: 'javascript:void(0);', title: 'Calender', icon: 'calendar', type: 'extLink' },
		{ path: 'javascript:void(0);', title: 'Social App', icon: 'zap', type: 'extLink' }
	];

	// Array
	items = new BehaviorSubject<Menu[]>([]);
	megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
	levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);

}
