import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  //baseUrl : string = 'https://retail.edinemenu.com/inventory/webapi/api/';//retail inventory
  baseUrl : string = 'https://inventory.edinemenu.com/webapi/api/';
  // baseUrl : string = 'https://inventory.edinemenu.com/webapi/api/';
  //baseUrl : string = 'http://localhost:1308/api/';

  constructor(private http : HttpClient) { 
    
  }


  getApi(url : string){
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "bearer " + localStorage.getItem("userToken")
      }),
    };
    return this.http.get(this.baseUrl + url, httpOptions);
  }


  optionApi(url : string){
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "bearer " + localStorage.getItem("userToken")
      }),
    };
    return this.http.options(this.baseUrl + url, httpOptions);
  }


  postApi(url : string , requestObj : any){
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http
      .post(this.baseUrl + url, requestObj, httpOptions)
  }
  postFormDataApi(url : string , requestObj : any){
    let httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http
      .post(this.baseUrl + url, requestObj, httpOptions)
  }
  putApi(url : string , requestObj : any){
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http
      .put(this.baseUrl + url, requestObj, httpOptions)
  }
  putFormDataApi(url : string , requestObj : any){
    let httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http
      .put(this.baseUrl + url, requestObj, httpOptions)
  }


  deleteApi(url : string){
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http
      .delete(this.baseUrl + url,  httpOptions)
  }

  
}
